import { I as INTAKE_STATUS_COLORS, a as INTAKE_STATUS_TEXTS, b as INTAKE_STATUS_INCOMPLETE, c as INTAKE_STATUS_ENTERED, d as INTAKE_STATUS_SAMPLE_RECORDED, e as INTAKE_STATUS_AVAILABLE_FOR_UPLOAD, f as INTAKE_STATUS_READY_FOR_UPLOAD, g as INTAKE_STATUS_CANCELLED, h as INTAKE_STATUS_REJECTED } from "./intake.status.js";
import { MAINTAIN_PERMISSION, GROWERS_INTAKELIST } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { T as TRUCK_TYPE_INTAKE } from "./truck.types.js";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Intake",
      "has-active": false,
      "search-by": "IntakeNumber,CropCode",
      "ph-search-by": "Search by Intake, Crop"
    },
    scopedSlots: _vm._u([{
      key: "create-buttons",
      fn: function() {
        return [_vm.hasMaintainPermission ? _c("a-button", {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function($event) {
              return _vm.$emit("create-intake", false);
            }
          }
        }, [_vm._v("New Intake")]) : _vm._e(), _vm.hasMaintainPermission ? _c("a-button", {
          staticClass: "ml-2",
          on: {
            "click": function($event) {
              return _vm.$emit("create-intake", true);
            }
          }
        }, [_vm._v("Manual Intake")]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "customFilter",
      fn: function() {
        return [_c("div", {
          staticClass: "status-filter mr-2"
        }, [_c("select-filter", {
          attrs: {
            "source": "value",
            "source-label": "text",
            "data-source": _vm.filterOptions,
            "value": _vm.selectedStatus
          },
          on: {
            "change": _vm.onStatusFilterChange
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Detail")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1)];
      }
    }])
  }, [_c("url-field", {
    key: "IntakeNumber",
    attrs: {
      "data-index": "intakeNumber",
      "title": "Intake Number",
      "base-url": "/watties-grower/intakes"
    }
  }), _c("text-field", {
    key: "TruckCode",
    attrs: {
      "data-index": "truckCode",
      "title": "Truck"
    }
  }), _c("text-field", {
    key: "GrowerCode",
    attrs: {
      "data-index": "growerCode",
      "title": "Grower",
      "parse": _vm.parseGrowerCode
    }
  }), _c("text-field", {
    key: "ContractCode",
    attrs: {
      "data-index": "contractCode",
      "title": "Contract"
    }
  }), _c("text-field", {
    key: "CropCode",
    attrs: {
      "data-index": "cropCode",
      "title": "Crop Code",
      "parse": _vm.parseCropCode
    }
  }), _c("text-field", {
    key: "TimeIn",
    attrs: {
      "data-index": "timeIn",
      "title": "Time In",
      "parse": _vm.parseTimeIn
    }
  }), _c("tag-field", {
    key: "Status",
    attrs: {
      "data-index": "status",
      "title": "Status",
      "color": _vm.getStatusColor,
      "parse": _vm.parseStatus,
      "sorter": true
    }
  })], 1);
};
var staticRenderFns$2 = [];
var ListIntakes_vue_vue_type_style_index_0_scoped_true_lang = "";
const FIELD_STATUS = "Status";
const __vue2_script$2 = {
  name: "ListIntakes",
  inject: ["createRoute", "crud", "can"],
  data() {
    const filterOptions = [
      { value: "all", text: "All", color: "black" },
      { value: INTAKE_STATUS_INCOMPLETE },
      { value: INTAKE_STATUS_ENTERED },
      { value: INTAKE_STATUS_SAMPLE_RECORDED },
      { value: INTAKE_STATUS_AVAILABLE_FOR_UPLOAD },
      { value: INTAKE_STATUS_READY_FOR_UPLOAD },
      { value: INTAKE_STATUS_CANCELLED },
      { value: INTAKE_STATUS_REJECTED }
    ];
    filterOptions.forEach((option) => {
      if (option.value == "all")
        return;
      option.color = INTAKE_STATUS_COLORS[option.value];
      option.text = INTAKE_STATUS_TEXTS[option.value];
    });
    return {
      filterOptions,
      isCreating: false
    };
  },
  computed: {
    selectedStatus() {
      const statusFilter = this.crud.getFilters().get(FIELD_STATUS);
      return statusFilter || { value: "all" };
    },
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  created() {
    this.crud.setPagination({ page: 1, pageSize: 10 });
    this.crud.clearFilters();
  },
  methods: {
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    },
    getStatusColor(value) {
      return INTAKE_STATUS_COLORS[value];
    },
    parseCropCode(value, record) {
      if (!value)
        return "";
      return `${value} ${record.cropName}`;
    },
    parseGrowerCode(value, record) {
      if (!value || !record.growerName)
        return "";
      return `${value} - ${record.growerName}`;
    },
    parseStatus(value) {
      return INTAKE_STATUS_TEXTS[value];
    },
    parseTimeIn(value) {
      if (!value)
        return "";
      return this.$moment(value).format("D/M/Y hh:mm A");
    },
    onStatusFilterChange(status) {
      if (status.value == "all") {
        this.crud.deleteFilter(FIELD_STATUS);
        this.fetchList();
        return;
      }
      this.crud.setFilter(FIELD_STATUS, status);
      this.fetchList();
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "a9fbec92", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ListIntakes = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", {
    attrs: {
      "destroy-on-close": "",
      "title": "New Intake",
      "visible": _vm.visible,
      "width": 700
    },
    on: {
      "cancel": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("portal-target", {
          attrs: {
            "name": "new-intake-footer"
          }
        })];
      },
      proxy: true
    }])
  }, [_c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_vm.manual ? _c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Intake Number",
            "max-length": 11,
            "rules": "required"
          },
          on: {
            "change": _vm.onIntakeNumberChange
          }
        }) : _vm._e()], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Crop Code - Crop Name",
            "reference": "grower.common.crops",
            "rules": "required",
            "source": "id",
            "source-label": _vm.generateCropLabel
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("cropId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("select-input", {
          attrs: {
            "data-source": _vm.trucks,
            "form-item": "",
            "label": "Truck Code",
            "rules": "required",
            "source": "id",
            "source-label": "code"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("truckId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Warehouse",
            "data-source": _vm.warehouses,
            "rules": "required",
            "source": "id",
            "source-label": "code"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("warehouseId", $event);
            }
          }
        })], 1)], 1), _c("portal", {
          attrs: {
            "to": "new-intake-footer"
          }
        }, [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "loading": _vm.isCreating,
            "type": "primary"
          },
          on: {
            "click": function($event) {
              return handleSubmit(_vm.submit);
            }
          }
        }, [_vm._v("Create")])], 1)];
      }
    }])
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "NewIntake",
  inject: ["resourceProps"],
  props: {
    manual: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [intakeProps, truckProps, warehouseProps] = this.resourceProps;
    return {
      intakeProps,
      warehouseProps,
      isCreating: false,
      truckProps
    };
  },
  computed: {
    trucks() {
      return this.truckProps.crud.getList();
    },
    warehouses() {
      return this.warehouseProps.crud.getList().filter((val) => val.code !== "");
    }
  },
  watch: {
    visible(newVal) {
      if (!newVal)
        return;
      this.intakeProps.crud.updateEntity("createForm", {
        resourceKey: "intakeNumber",
        value: void 0
      });
    }
  },
  created() {
    const { apiUrl: apiUrl2 } = this.truckProps;
    this.truckProps.crud.fetchList(`${apiUrl2}/grower/common/trucks?truckTypeId=${TRUCK_TYPE_INTAKE}`, void 0, false);
    this.warehouseProps.crud.fetchList();
    this.intakeProps.crud.clearEntity("createForm");
    this.intakeProps.crud.clearEntity("editForm");
  },
  methods: {
    cancel() {
      this.$emit("update:visible", false);
    },
    generateCropLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    storeValue(resourceKey, value) {
      this.intakeProps.crud.updateEntity("editForm", { resourceKey, value });
    },
    async submit() {
      this.isCreating = true;
      try {
        const { apiUrl: apiUrl2 } = this.intakeProps;
        const res = await this.intakeProps.crud.submitEntity("create");
        const { id } = res.data;
        await this.intakeProps.crud.submitEntity("replace", `${apiUrl2}/grower/intakes/${id}`);
        this.$emit("update:visible", false);
        this.$router.push(this.intakeProps.createRoute.replace(":id", id));
      } finally {
        this.isCreating = false;
      }
    },
    onIntakeNumberChange(value) {
      this.intakeProps.crud.updateEntity("createForm", {
        resourceKey: "intakeNumber",
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var NewIntake = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-intakes"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "page": _vm.page,
      "name": "grower.intakes",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/intakes/:id?create=1",
      "edit-route": "/watties-grower/intakes/:id",
      "filter-route": "/watties-grower/intakes/advanced-search"
    }
  }, [_c("list-intakes", {
    on: {
      "create-intake": _vm.onCreateIntake
    }
  })], 1), _vm.hasMaintainPermission ? _c("resource", {
    attrs: {
      "name": ["grower.intakes", "grower.common.trucks", "grower.common.ware-houses"],
      "api-url": _vm.apiUrl,
      "create-route": ["/watties-grower/intakes/:id?create=1"]
    }
  }, [_c("new-intake", {
    attrs: {
      "manual": _vm.manual,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function($event) {
        _vm.visible = $event;
      }
    }
  })], 1) : _vm._e(), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListIntakes,
    NewIntake
  },
  data() {
    return {
      apiUrl,
      manual: false,
      page: GROWERS_INTAKELIST,
      menuItems: [
        {
          key: "intakeprocessing",
          title: "Intake Processing",
          path: ""
        },
        {
          key: "intakelist",
          title: "Intake List",
          path: "/watties-grower/intakes"
        }
      ],
      visible: false
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.$can(MAINTAIN_PERMISSION, this.page);
    }
  },
  methods: {
    onCreateIntake(manual) {
      this.manual = manual;
      this.visible = true;
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
